<!--派单规则设置-->
<template>
  <div style="padding: 20px;">
    <div style="display: flex;justify-content: space-between;align-items: center;">
      <div>
        <span style="font-size: 24px;font-weight: 500;color: #707070;">派单规则设置</span>
      </div>
      <div>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <el-card shadow="never" style="margin-top: 20px;">
      <div>
        <el-button class="custom-button" style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;">
          <i class="el-icon-devops" />
          <span style="vertical-align: middle">下载批量模板</span>
        </el-button>
        <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
          <i class="el-icon-devops" />
          <span style="vertical-align: middle">批量导入服务商</span>
        </el-button>
      </div>

      <div style="margin-top: 20px;border: 1px solid #eeeeee;padding: 20px;">
        <div>
          <span style="font-size: 18px;font-weight: 500;color: #4e4e4e;">区域：</span>
        </div>
        <div style="display: flex;">
          <div class="radio">
            <el-radio-group v-model="radio1">
              <el-radio label="1">
                <span>四川省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="2">
                <span>河南省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="3">
                <span>黑龙江省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="4">
                <span>广东省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="5">
                <span>重庆市</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
            </el-radio-group>
          </div>

          <div class="radio">
            <el-radio-group v-model="radio1">
              <el-radio label="1">
                <span>四川省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="2">
                <span>河南省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="3">
                <span>黑龙江省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="4">
                <span>广东省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="5">
                <span>重庆市</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
            </el-radio-group>
          </div>

          <div class="radio">
            <el-radio-group v-model="radio1">
              <el-radio label="1">
                <span>四川省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="2">
                <span>河南省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="3">
                <span>黑龙江省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="4">
                <span>广东省</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
              <el-radio label="5">
                <span>重庆市</span>
                <img src="@/assets/images/home/yunying/jiantou.png" alt="" style="width: 6px;height: 11px;margin-left: 50px;">
              </el-radio>
            </el-radio-group>
          </div>

        </div>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div style="display: flex;align-items: center;">
            <span style="font-size: 18px;font-weight: 500;color: #4e4e4e;">全选：</span>
            <span style="margin-left: 20px;">
               <el-checkbox v-model="checked">救援类型</el-checkbox>
               <el-checkbox v-model="checked1">自动调度</el-checkbox>
            </span>
          </div>
          <div style="display: flex;align-items: center;">
            <span v-if="value === true" style="font-size: 16px;font-weight: 500;color: #3f4157;margin-right: 10px;">已启用</span>
            <span v-else style="font-size: 16px;font-weight: 500;color: #3f4157;margin-right: 10px;">已关闭</span>
            <el-switch
              v-model="value">
            </el-switch>
          </div>
        </div>

        <!--拖车-->
        <div style="border: 1px solid #d8dadc;border-radius: 5px;padding: 20px;margin-top: 15px;">
          <div style="display: flex;justify-content: space-between;">
            <div>
              <span>
               <el-checkbox v-model="checked2">拖车</el-checkbox>
               <el-checkbox v-model="checked3">自动调度</el-checkbox>
              </span>
              <span style="margin-left: 20px;">
                <span style="margin-right: 10px;">关键词</span>
                <el-input
                  style="width: 302px !important;"
                  clearable
                  v-model="form.name"
                  placeholder="请输入关键词"/>
              </span>
            </div>
            <div>
              <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" @click="add()">添加</span>
              </el-button>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :headerCellStyle="headerCellStyle"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >

              <!--自定义列显示 示例-->
              <template v-slot:name= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.name}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.name"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:bili= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.bili}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.bili"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:fenzhong= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.fenzhong}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.fenzhong"
                    placeholder="请输入"/>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="info" v-if="scope.row.biaoshi == 1" @click="DelList(scope.row,scope.$index)">删除</el-link>
                <el-link :underline="false" type="warning" v-if="scope.row.biaoshi == 0" @click="ListSave(scope.row)">保存</el-link>
              </template>
            </my-table>
          </div>
        </div>

        <!--搭电-->
        <div style="border: 1px solid #d8dadc;border-radius: 5px;padding: 20px;margin-top: 15px;">
          <div style="display: flex;justify-content: space-between;">
            <div>
              <span>
               <el-checkbox v-model="dadian">搭电</el-checkbox>
               <el-checkbox v-model="dadiantiaozheng">自动调度</el-checkbox>
              </span>
              <span style="margin-left: 20px;">
                <span style="margin-right: 10px;">关键词</span>
                <el-input
                  style="width: 302px !important;"
                  clearable
                  v-model="form.name"
                  placeholder="请输入关键词"/>
              </span>
            </div>
            <div>
              <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" @click="dadianadd()">添加</span>
              </el-button>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columnsdadian"
              :tableData="Listdadian"
              :headerCellStyle="headerCellStyle"
              :loading="loading1"
            >
              <!--自定义列显示 示例-->
              <template v-slot:name= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.name}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.name"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:bili= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.bili}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.bili"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:fenzhong= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.fenzhong}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.fenzhong"
                    placeholder="请输入"/>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="info" v-if="scope.row.biaoshi == 1" @click="dadianDelList(scope.row,scope.$index)">删除</el-link>
                <el-link :underline="false" type="warning" v-if="scope.row.biaoshi == 0" @click="dadianListSave(scope.row)">保存</el-link>
              </template>
            </my-table>
          </div>
        </div>

        <!--换胎-->
        <div style="border: 1px solid #d8dadc;border-radius: 5px;padding: 20px;margin-top: 15px;">
          <div style="display: flex;justify-content: space-between;">
            <div>
              <span>
               <el-checkbox v-model="huantai">换胎</el-checkbox>
               <el-checkbox v-model="huantaitiaozheng">自动调度</el-checkbox>
              </span>
              <span style="margin-left: 20px;">
                <span style="margin-right: 10px;">关键词</span>
                <el-input
                  style="width: 302px !important;"
                  clearable
                  v-model="form.name"
                  placeholder="请输入关键词"/>
              </span>
            </div>
            <div>
              <el-button class="custom-button" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;">
                <i class="el-icon-tianjia" />
                <span style="vertical-align: middle" @click="huantaiadd()">添加</span>
              </el-button>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columnshuantai"
              :tableData="Listhuantai"
              :headerCellStyle="headerCellStyle"
              :loading="loading2"
            >
              <!--自定义列显示 示例-->
              <template v-slot:name= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.name}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.name"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:bili= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.bili}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.bili"
                    placeholder="请输入"/>
                </div>
              </template>

              <template v-slot:fenzhong= "{ scope }">
                <div v-if="scope.row.biaoshi == 1">
                  {{scope.row.fenzhong}}
                </div>
                <div v-else>
                  <el-input
                    clearable
                    v-model="scope.row.fenzhong"
                    placeholder="请输入"/>
                </div>
              </template>

              <!-- 不使用数据可不接收scope值 -->
              <template v-slot:operationSlot= "{ scope }">
                <el-link :underline="false" type="info" v-if="scope.row.biaoshi == 1" @click="huantaiDelList(scope.row,scope.$index)">删除</el-link>
                <el-link :underline="false" type="warning" v-if="scope.row.biaoshi == 0" @click="huantaiListSave(scope.row)">保存</el-link>
              </template>
            </my-table>
          </div>
        </div>


      </div>

    </el-card>

    <div style="margin-top: 20px;text-align: center;">
      <el-button style="width: 240px;">取消</el-button>
      <el-button
        style="margin-left: 20px;width: 240px;"
        type="primary"
        @click="save()">提交
      </el-button>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form:{},

      radio1:'四川省',

      checked:true,
      checked1:false,

      value:true,

      checked2:true,
      checked3:false,

      // 表格搜索条件
      where: {},
      // 表格数据
      List: [
        {
          name:'新华保险股份有限公司河南省郑州市分公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        },
        {
          name:'海信集团有限公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        }
      ],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          label: "服务商名称",
          prop: "name",
          slot: 'name',
          isShow: true
        },
        {
          label: "派单比例 %",
          prop: "bili",
          slot: 'bili',
          isShow: true
        },
        {
          label: "超时自动撤回(分钟)，0 不撤回正数为补款，负数为扣款",
          prop: "fenzhong",
          slot: 'fenzhong',
          isShow: true
        },
        {
          label: "操作",
          width: "240",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      //搭电
      dadian:true,
      dadiantiaozheng:false,
      // 表格数据
      Listdadian: [
        {
          name:'新华保险股份有限公司河南省郑州市分公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        },
        {
          name:'海信集团有限公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        }
      ],
      //加载动画
      loading1:true,
      // 表头数据
      columnsdadian: [
        {
          label: "服务商名称",
          prop: "name",
          slot: 'name',
          isShow: true
        },
        {
          label: "派单比例 %",
          prop: "bili",
          slot: 'bili',
          isShow: true
        },
        {
          label: "超时自动撤回(分钟)，0 不撤回正数为补款，负数为扣款",
          prop: "fenzhong",
          slot: 'fenzhong',
          isShow: true
        },
        {
          label: "操作",
          width: "240",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

      //换胎
      huantai:true,
      huantaitiaozheng:false,
      // 表格数据
      Listhuantai: [
        {
          name:'新华保险股份有限公司河南省郑州市分公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        },
        {
          name:'海信集团有限公司',
          bili:'5',
          fenzhong:'5',
          biaoshi:1,
        }
      ],
      //加载动画
      loading2:true,
      // 表头数据
      columnshuantai: [
        {
          label: "服务商名称",
          prop: "name",
          slot: 'name',
          isShow: true
        },
        {
          label: "派单比例 %",
          prop: "bili",
          slot: 'bili',
          isShow: true
        },
        {
          label: "超时自动撤回(分钟)，0 不撤回正数为补款，负数为扣款",
          prop: "fenzhong",
          slot: 'fenzhong',
          isShow: true
        },
        {
          label: "操作",
          width: "240",
          slot: "operationSlot",
          isShow: true,
          fixed: 'right'
        },
      ],

    }
  },

  mounted() {
    this.loading = false;
    this.loading1 = false;
    this.loading2 = false;
  },

  methods:{
    //点击返回按钮
    returnPage(){
      this.$emit('showDetails',false);
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },

    //拖车添加
    add(){
      this.List.push({
        name:'',
        bili:'',
        fenzhong:'',
        biaoshi:0,
      })
    },
    //点击保存
    ListSave(row){
      row.biaoshi = 1;
    },
    //点击删除
    DelList(row,index){
      // console.log(row)
      // console.log(index)
      this.List.splice(index,1)
    },

    //搭电
    dadianadd(){
      this.Listdadian.push({
        name:'',
        bili:'',
        fenzhong:'',
        biaoshi:0,
      })
    },
    //点击保存
    dadianListSave(row){
      row.biaoshi = 1;
    },
    //点击删除
    dadianDelList(row,index){
      // console.log(row)
      // console.log(index)
      this.Listdadian.splice(index,1)
    },

    //换胎
    huantaiadd(){
      this.Listhuantai.push({
        name:'',
        bili:'',
        fenzhong:'',
        biaoshi:0,
      })
    },
    //点击保存
    huantaiListSave(row){
      row.biaoshi = 1;
    },
    //点击删除
    huantaiDelList(row,index){
      this.Listhuantai.splice(index,1)
    },


  },

}
</script>

<style lang="scss" scoped>

</style>
